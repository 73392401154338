import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Social from '@widgets/Social'
import HeroComponent from '../components/Hero/Hero'
import { useBlogTags, useBlogCategories } from '@helpers-blog'
import AuthorExpanded from "@widgets/AuthorExpanded";
import {Box, Card, Flex, Text} from "theme-ui";
import Section from "@components/Section";

const styles = {
    imageWrapper: {
        borderRadius: `default`,
        overflow: `hidden`,
        position: `relative`
    },
    button: {
        display: [`none`, `block`],
        position: `absolute`,
        bottom: 4,
        right: 4
    },
    grid: {
        flexWrap: [`wrap`, null, `nowrap`],
        ' > div + div': {
            ml: [0, 0, 5]
        }
    },
    column: {
        flex: `auto`,
        flexBasis: [`full`, null, `1/2`]
    }
}


const Posts = ({ data: { paginatedPosts = {} }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const tags = useBlogTags()
  const categories = useBlogCategories()
    let admin = props?.pageResources?.json?.data?.admin;
  return (
    <Layout {...props}>
      <Seo title='Home' />
      {/*<Hero sx={{ bg: `contentBg` }}>*/}
      {/*  <HeroComponent {...props} />*/}
      {/*</Hero>*/}
      <Stack>
          <Main>
              <Divider />
              <AuthorExpanded author={admin} />
              <Divider />
              <Flex sx={styles.grid}>

                  <Box sx={styles.column}>
                      <Section aside={true} title='Highlights'/>
                      <Section title='2020 - Software Engineer'>
                          <Card variant='paper'>
                              Develop features, enhancements, and fixes to a robotic fulfillment solution for one of the largest supermarket chains in North America.
                          </Card>
                      </Section>
                      <Divider />
                      <Section title='2016 - Corporate Finance & Accounting'>
                          <Card variant='paper'>
                              General Ledger accounting and support senior FP&A team in expense analysis, headcount budgeting, and scenario planning.
                          </Card>
                      </Section>
                      <Divider />
                      <Section title='2011 - Business Administration'>
                          <Card variant='paper'>
                              Skilled in specialized CAD software to draft designs with onsite carpentry. Inventory, bookkeeping, sales and support.
                          </Card>
                      </Section>
                  </Box>

                  <Box sx={styles.column}>
                      <Section title='Experience'>
                          <Text variant='p'>
                              Experienced in the full Software Development Life Cycle (SDLC) processes including designing of software components, writing maintainable code, updating technical documentation, and ensuring business requirements are thoroughly tested.
                          </Text>
                          <Text variant='p'>
                              <ul>
                                  <li>Collaborating in a Scrum environment to meet deadlines, review pull requests and participate in Sprint planning, daily stand-ups and retrospectives.</li>
                                  <li>Designed and implemented Java/Spring backend services with Azure Cosmos Db and frontend clients with React/Angular as containerized microservices.</li>
                                  <li>Setup and maintain CI/CD pipeline in Jenkins for automated testing and deployment in either Kubernetes cluster or Pivotal Cloud Foundry Org Space.</li>
                              </ul>
                          </Text>
                          <Text variant='p'>
                              Ensured the integrity of expense, budget, and scenario analysis as part of FP&A team financial planing. Provided General Ledger support and analysis inclusive of period-end financial close responsibilities.
                          </Text>
                          <Text variant='p'>
                              <ul>
                                  <li>Designed queries in Oracle Fusion, PowerBI, and SQL Server to provide ad hoc expense reporting and analysis support for senior leaders and cost center managers.</li>
                                  <li>Automation of finance department’s manual processes by developing Windows desktop application in C# and Excel/Access VBA Code.</li>
                                  <li>Experienced in Oracle E-Business Suite AR/AP/OM and Hyperion including Essbase, Planning, Management, Reporting, and Smart View Excel Add-In.</li>
                              </ul>
                          </Text>
                          <Text variant='p'>
                              Performed bookkeeping, sales and administrative activities including weekly analysis of financial and legal documents.
                          </Text>
                          <Text variant='p'>
                              <ul>
                                  <li>Assisted in design of Kitchen & Bath Cabinetry layouts and renderings in 2020 Design Software and AutoCAD.</li>
                                  <li>Management with the operations of receiving inventory, auditing merchandise and coordinating projects.</li>
                                  <li>Built a local Microsoft SQL server database interfaced with excel to digitize and organize decade of paperwork.</li>
                              </ul>
                          </Text>
                      </Section>
                  </Box>


              </Flex>
          </Main>
      </Stack>
      <Divider />
      {/*<Stack>*/}
      {/*  <Main>*/}
      {/*    <CardList*/}
      {/*      variant={['horizontal-md', 'horizontal']}*/}
      {/*      title='Recently Published'*/}
      {/*      nodes={paginatedPosts.nodes}*/}
      {/*      columns={[1]}*/}
      {/*    />*/}
      {/*  </Main>*/}
      {/*  <Sidebar>*/}
      {/*    <Categories categories={categories} />*/}
      {/*    <Divider />*/}
      {/*    <Tags tags={tags} />*/}
      {/*    <Divider />*/}
      {/*    <Social />*/}
      {/*    <Sticky>*/}
      {/*      {services.mailchimp && (*/}
      {/*        <>*/}
      {/*          <Divider />*/}
      {/*          <NewsletterCompact />*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </Sticky>*/}
      {/*  </Sidebar>*/}
      {/*</Stack>*/}
      {/*<Divider />*/}
      {/*<PreFooter>*/}
      {/*  <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />*/}
      {/*</PreFooter>*/}
    </Layout>
  )
}

export default Posts
